<template>
  <div class="">
    <div class="row mt-4">
      <!-- First Column -->

      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <form v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Règlement</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <div class="">
              <div class="fieldset mt-3">
                <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>
                <b-form-select
                  v-model="getdevisMonoFiche.data.devis.moyens_paiement"
                  :options="optionspaiement"
                  text-field="text"
                  value-field="value"
                  required
                  class="form-input custom-placeholder custom-input"
                ></b-form-select>
              </div>
            </div>

            <b-form-group label="Debut travaux">
              <b-form-input
                type="date"
                v-model="getdevisMonoFiche.data.devis.debut_travaux"
                required
                class="form-input custom-placeholder custom-input"
                @input="
                  updateDevis(
                    'debut_travaux',
                    getdevisMonoFiche.data.devis.debut_travaux
                  )
                "
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Fin travaux">
              <b-form-input
                type="date"
                v-model="getdevisMonoFiche.data.devis.fin_travaux"
                required
                @input="
                  updateDevis(
                    'fin_travaux',
                    getdevisMonoFiche.data.devis.fin_travaux
                  )
                "
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>

      <!-- Second Column -->
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>

        <form v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Mandataire</div>
            </div>
          </div>
          <div class="borderPointier mt-3 pt-3 px-3">
            <b-form-group label="Mandataire">
              <multiselect
                v-model="selectedMondataire"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getMondataireEntitesExternes"
                label="nom"
                track-by="id"
                :placeholder="$t('Select_agent')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
          </div>
          <v-container v-if="progress" type="image" class="loding">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-container>
          <div v-else class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">Partenaire</div>
              </div>
            </div>

            <b-form-group label="Partenaire">
              <multiselect
                v-model="selectedPartenaire"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getPartenaireEntitesExternes"
                label="nom"
                track-by="id"
                :placeholder="$t('Select_agent')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
    <!--button-->
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        Précédent
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        Suivant
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moyens_paiement: "financement",
      partenaire: false,
      mondataire: false,

      optionspaiement: [
        { value: "financement", text: this.$t("FINANCEMENT") },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
      ],
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getdevisMonoFiche",
      "getMondataireEntitesExternes",
      "getPartenaireEntitesExternes",
    ]),
    devisData() {
      return this.getdevisMonoFiche?.data?.devis || {};
    },
    selectedPartenaire: {
      get() {
        if (typeof this.partenaire === "object" && this.partenaire !== null) {
          return this.partenaire;
        } else {
          return this.getPartenaireEntitesExternes.find(
            (option) => option.id === this.partenaire
          );
        }
      },
      set(value) {
        this.partenaire = value ? value.id : null;
        this.getdevisMonoFiche.data.devis.partenaire_id = value
          ? value.id
          : null;
        this.getdevisMonoFiche.data.devis.partenaire = {
          id: value.id,
          nom: value.nom,
          siret: value.siret,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      },
    },
    selectedMondataire: {
      get() {
        if (typeof this.mondataire === "object" && this.mondataire !== null) {
          return this.mondataire;
        } else {
          return this.getMondataireEntitesExternes.find(
            (option) => option.id === this.mondataire
          );
        }
      },
      set(value) {
        this.mandataire = value ? value.id : null;
        this.getdevisMonoFiche.data.devis.mandataire_id = value.id;
        this.getdevisMonoFiche.data.devis.mandataire = {
          id: value.id,
          nom: value.nom,
          siret: value.siret,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      },
    },
  },

  created() {
    this.allEntitesExternes({
      page: 1,
      per_page: 10,
      search: "",
      type: "",
    });

    if (this.getdevisMonoFiche.data && this.getdevisMonoFiche.data.devis) {
      this.initializeDateData();
      this.formatDates();
    }
  },
  watch: {
    getdevisMonoFiche: {
      handler(newVal) {
        if (newVal?.data?.devis) {
          this.partenaire = newVal.data.devis.partenaire || null;
          this.mondataire = newVal.data.devis.mandataire || null;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["allEntitesExternes", "setDevisMonoFiche"]),
    goToNextStep() {
      this.$emit("changeStep", 8, true);
    },
    goToPrevStep() {
      this.$emit("changeStep", 6);
    },
    initializeDateData() {
      const devis = this.getdevisMonoFiche.data.devis;

      if (!devis.debut_travaux) {
        devis.debut_travaux = "";
      }
      if (!devis.fin_travaux) {
        devis.fin_travaux = "";
      }
    },
    selectMondataire(mondataire) {
      this.mondataire = { ...mondataire };

      this.getdevisMonoFiche.data.devis.mandataire_id = mondataire.id;
    },
    updateDevis(field, value) {
      this.getdevisMonoFiche.data.devis[field] = value;

      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    selectPartenaire(partenaire) {
      this.partenaire = { ...partenaire };
      this.getdevisMonoFiche.data.devis.partenaire_id = partenaire.id;
    },
    formatDates() {
      const addDay = 24 * 60 * 60 * 1000;

      if (this.getdevisMonoFiche.data.devis.debut_travaux) {
        const debutDate = new Date(
          this.getdevisMonoFiche.data.devis.debut_travaux
        );
        this.getdevisMonoFiche.data.devis.debut_travaux = new Date(
          debutDate.getTime() + debutDate.getTimezoneOffset() * 60000 + addDay
        )
          .toISOString()
          .slice(0, 10);
      }

      if (this.getdevisMonoFiche.data.devis.fin_travaux) {
        const finDate = new Date(this.getdevisMonoFiche.data.devis.fin_travaux);
        this.getdevisMonoFiche.data.devis.fin_travaux = new Date(
          finDate.getTime() + finDate.getTimezoneOffset() * 60000 + addDay
        )
          .toISOString()
          .slice(0, 10);
      }
    },

    created() {
      this.selectPartenaire(this.getdevisMonoFiche.data.partenaire);
      this.selectMondataire(this.getdevisMonoFiche.data.mandataire);
    },
  },
};
</script>
<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.custom-input {
  box-shadow: none;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>

<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <stepperGeneric :steps="steps" @complete="handleCompletion"></stepperGeneric>
  </div>
</template>

<script>
import stepperGeneric from '../../components/stepper/devis143/stepperGeneric.vue';
import stepAdresseBar143 from '../../components/stepper/devis143/stepAdresseBar143.vue';
import stepChaniterBar143 from '../../components/stepper/devis143/stepChaniterBar143.vue';
import stepClientBar143 from '../../components/stepper/devis143/stepClientBar143.vue';
import stepGeneraliteBar143 from '../../components/stepper/devis143/stepGeneraliteBar143.vue';
import stepInformationBar143 from '../../components/stepper/devis143/stepInformationBar143.vue';
import stepInstallateurBar143 from '../../components/stepper/devis143/stepInstallateurBar143.vue';
import stepReglementBar143 from '../../components/stepper/devis143/stepReglementBar143.vue';
import stepDevisBar143 from '../../components/stepper/devis143/stepDevisBar143.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    stepperGeneric,
  
  },
  data() {
    return {
      steps: [
        { label: 'Généralité', component: stepGeneraliteBar143 },
        { label: 'Client', component: stepClientBar143 },
        { label: 'Installateur', component: stepInstallateurBar143 },
        { label: 'Adresse', component: stepAdresseBar143 },
        { label: 'Information', component: stepInformationBar143 },
        { label: 'Chantier', component: stepChaniterBar143 },
        { label: 'Règlement', component: stepReglementBar143 },
        { label: 'Devis', component: stepDevisBar143 },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTabs"]),
  },
  methods: {
    handleCompletion() {
      this.$router.push('/devis/list');
    },
  },
};
</script>

  
  <style scoped>
  .inner-container .content {
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000439;
    border-radius: 5px;
    padding: 14px;
    min-height: 88vh;
    margin-top: 55px;
  }
  </style>